import { background, filter, shadow } from './elevation';
import colors from './colors/';

const mixin = {
  validateMode: mode => colors => {
    if (typeof colors === 'string') {
      return colors;
    }
    const customColors = {
      light: colors.light,
      dark: colors.dark,
    };
    return customColors[mode];
  },
  backgroundMode: mode => {
    const customColors = {
      light: colors.neutral[100],
      dark: background.dark[5],
    };
    return customColors[mode];
  },
  surfaceTheme: mode => scale => colors.surface[mode][scale],
  shadowTheme: mode => scale => shadow[mode][scale],
  backgroundTheme: mode => scale => background[mode][scale],
  filterTheme: mode => scale => filter[mode][scale],
  pxToRem: value => `${value / 16}rem`,
};

export default mixin;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const ProgressBarContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 40%;
    @media screen and (max-width: 768px) {
      max-width: unset;
    }
  `}
`;

export const LinearProgressContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    margin-right: 0.5rem;
  `}
`;

export const ProgressLabelContainer = styled(Box)`
  ${({ theme }) => css`
    min-width: 2.25rem;
    .MuiTypography-root {
      font-weight: 500;
    }
  `}
`;

export const StyledLinearProgress = styled(LinearProgress)`
  ${({ theme }) => css`
    height: 0.625rem;
    border-radius: 100px;
    .MuiLinearProgress-bar {
      border-radius: 100px;
    }
  `}
`;

import cn from 'classnames';
import { cloneDeep } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import pkg from '../package.json';
import { AppHeader } from './AppHeader';
import { API } from './components/api';
import { AuthProvider } from './components/auth';
import { DrawerProvider } from './components/drawer';
import { Column } from './components/layout';
import { ModalProvider } from './components/modal';
import { UserProvider } from './components/user';
import fullStoryInit from './fullstory';
import { FullStoryIdentify } from './FullStoryIdentify';
import NewSidebar from './layout/new-sidebar';
import { ProductionWarningProvider } from './ProductionWarningProvider';
import AppContextProvider from './util/app-provider/AppContext';
import { EntityContextProvider } from './util/entity-provider/EntityProvider';
import { VersionHandler } from './version-handler/VersionHandler';
import { ChatProvider } from './components/chat';
import CommonContextProviders from './CommonContextProviders';

const Styled = styled(Column)`
  transition: all 0.3s;
  background-color: var(--color-background-page);
  color: var(--color-text-page);
  font-family: 'Work Sans', serif;
  ${({ contentOnly }) => (contentOnly ? 'max-height: 100%' : 'height: 100vh')};
  width: 100%;
  gap: 0;
  .ant-drawer-body {
    padding-top: 0;
  }
`;

export const ConnexDesignSystem = ({
  onLogin,
  toLogin,
  children,
  className,
  messages,
  initialLocale,
  initialTimeZone,
  app,
  isIframe = true,
  appId,
  analytics = true,
  contentOnly = false, // Hides sidebar and header.
  queryClient,
  setApiSource = true,
  pathPrefix = '',
  ...props
}) => {
  React.useEffect(() => {
    if (analytics) fullStoryInit();
  }, [analytics, appId]);

  React.useEffect(() => {
    if (queryClient) {
      const ONE_HOUR = 1000 * 60 * 60;
      const TWELVE_HOURS = ONE_HOUR * 12;

      const options = cloneDeep(queryClient.getDefaultOptions());

      const defaultQueryOptions = {
        retry: (failureCount, error) => error?.status < 400 || error?.status >= 500,
        staleTime: 5000,
        cacheTime: TWELVE_HOURS,
      };

      const mergedDefaultOptions = {
        ...options,
        queries: {
          ...defaultQueryOptions,
          ...options.queries,
        },
      };
      queryClient.setDefaultOptions?.(mergedDefaultOptions);
    }
  }, [queryClient]);

  if (setApiSource) {
    API.setSource(appId);
  }

  return (
    <Styled className={cn('cds-root-container', `v-${pkg.version}`, className)} contentOnly={contentOnly} {...props}>
      <CommonContextProviders
        messages={messages}
        initialLocale={initialLocale}
        appId={appId}
        isIframe={isIframe}
        initialTimeZone={initialTimeZone}
        {...props}
      >
        <VersionHandler>
          <UserProvider>
            <AuthProvider onLogin={onLogin} toLogin={toLogin}>
              <ModalProvider>
                <ChatProvider>
                  <DrawerProvider>
                    <EntityContextProvider>
                      <AppContextProvider>
                        <FullStoryIdentify />
                        <ProductionWarningProvider>
                          {contentOnly ? (
                            children
                          ) : (
                            <NewSidebar pathPrefix={pathPrefix}>
                              <AppHeader />
                              {children}
                            </NewSidebar>
                          )}
                        </ProductionWarningProvider>
                      </AppContextProvider>
                    </EntityContextProvider>
                  </DrawerProvider>
                </ChatProvider>
              </ModalProvider>
            </AuthProvider>
          </UserProvider>
        </VersionHandler>
      </CommonContextProviders>
    </Styled>
  );
};
